.actions-top-menu {
    min-width: min-content;
    flex: 1;
    display: flex;
    margin-right: 24px;

    &__mode-container {
        margin-left: 10px;
    }
}
