.column-layout {
    flex: 1 1 0;
    min-height: 0;

    &--with-banner &__first-column {
        padding-bottom: 88px;
    }

    &--with-banner &__second-column {
        padding-bottom: 88px;
    }

    &__first-column {
        max-height: 100%;
        display: flex;
        flex-direction: column;

        &--hidden {
            visibility: hidden;
        }
    }

    &__first-column-container {
        max-height: 100%;
        overflow: auto;
        padding: 0 24px 24px;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
    }

    &__second-column,
    &__third-column {
        overflow: visible;
        display: flex;
        flex-direction: column;
        max-height: 100%;

        &--hidden {
            visibility: hidden;
        }
    }

    &__column-container {
        max-height: 100%;
        overflow: auto;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        overflow-x: hidden;
        flex-grow: 1;

        > *:first-child {
            flex-grow: 1;
            padding: 0 24px 24px;
        }
    }

    &__column-inner-container {
        display: flex;
        flex-direction: column;
    }
}
