.base-layout {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &__header {
        display: flex;
        align-items: center;
        padding: 0;
    }

    &__logo-container {
        padding: 0 10px;
        max-height: 100%;

        img {
            width: 60px;
            height: auto;
        }
    }

    &__actions-menu-container {
        margin-left: auto;
    }

    &__layout {
        max-height: calc(100vh - 64px);
    }

    &__inner-layout {
        padding: 0 24px 24px;
    }

    &__content {
        overflow: auto;
        display: flex;
        overflow-x: hidden;
        flex-direction: column;
        flex-grow: 1;
    }

    &__top-content {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin: 24px 0;
    }
}
