.media-list-item {
    display: block;

    &__content {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        width: 100%;

        > *:first-child {
            width: 300px;
            min-width: 300px;
            max-width: 300px;
        }
    }

    &__description {
        display: flex;
        flex-direction: column;
        padding-left: 20px;
        padding-right: 35px;
        flex-grow: 1;
        align-self: stretch;

        > *:not(:last-child) {
            margin-bottom: 10px;
        }
    }

    &__description-item {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
    }

    &__description-item-content {
        display: flex;
        justify-content: space-between;
    }

    &__description-name-field {
        display: flex;
        align-items: flex-start;
        flex-direction: column;

        > *:not(:last-child) {
            flex-grow: 1;
            margin-right: 10px;
            margin-bottom: 10px;
        }
    }

    &__input-container {
        width: 100%;

        > *:first-child {
            display: block;
            margin-bottom: 5px;
        }
    }

    &__filename-label {
        display: block;
        margin-bottom: 5px;
    }
}
