.episode-selector {
    margin-bottom: 20px;
    position: absolute;
    top: -5px;
    right: 24px;
    z-index: 10;

    &__select {
        width: 240px;
    }
}
