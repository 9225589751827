#root {
    min-height: 100vh;
    display: flex;
}

.ant-spin-nested-loading {
    width: 100%;
    flex: 1;
    display: flex;
}

main > .ant-spin-nested-loading {
    max-height: 100%;
    overflow: scroll;
    padding: 24px 0 0;
}

.ant-spin-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    max-height: 100%;
    overflow: hidden;
}

main .ant-spin-container {
    overflow: hidden;
}

.ant-pagination {
    margin-bottom: 15px;
}

.ant-table-placeholder {
    z-index: 8 !important;
}

.ant-modal-body .ant-tabs {
    max-height: calc(100vh - 260px);
    overflow: scroll;
    padding-right: 10px;
}

.ant-modal-body .ant-tabs-nav {
    position: sticky;
    top: 0;
    z-index: 2;
}

.ant-spin-nested-loading .ant-spin-blur {
    opacity: 0;
}

.ant-table-wrapper .ant-spin-nested-loading .ant-spin-blur {
    opacity: 0.1;
}

.ant-table-selection-extra {
    display: none;
}

.full-page-tabs .ant-tabs-nav-wrap {
    padding: 0 24px;
}

.ant-spin-spinning .ant-spin-container {
    transition: opacity 0.6s !important;
}

.ant-spin-container {
    transition: opacity 0.2s !important;
}

.ant-list > .ant-list-pagination:first-child {
    margin-top: 5px !important;
}

header .ant-menu.ant-menu-horizontal {
    border-bottom: 0 !important;
}

.ant-form-horizontal .ant-form-item-control {
    display: flex;
    align-items: flex-end;
}

.ant-form-item-control-input-content > .ant-color-picker-trigger {
    width: 120px;
}

.ant-color-picker-trigger > .ant-color-picker-color-block {
    width: 100% !important;
}

.ant-color-picker-trigger-disabled > div > div {
    display: none;
}
