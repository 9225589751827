.actions-banner {
    position: sticky;
    bottom: -24px;
    margin: auto -24px -24px;
    width: calc(100% + 48px);
    z-index: 9;
    margin: 0 auto;
    width: calc(100% + 56px);
    bottom: 0;
    align-self: flex-end;

    &__container {
        padding: 24px;
    }
}
