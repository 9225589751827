.searchable-table {
    &__search-input {
        margin-top: 10px;
        margin-bottom: 10px;
        position: sticky !important;
        top: 60px;
        z-index: 9;

        &::before {
            content: "";
            height: 5px;
            width: 100%;
            position: absolute;
            top: -5px;
            left: 0;
        }
    }
}
