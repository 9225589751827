.stick-to-top {
    margin-bottom: 20px;
    width: 100%;
    z-index: 5;
    display: flex;
    padding: 5px 0 15px;
    position: sticky;
    top: 0;
    flex-direction: column;

    &__main-content {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: flex-end;

        & > *:first-child {
            margin-right: 20px;
            margin-bottom: 0;
            margin-top: 3px;
        }
    }

    &__actions-container {
        display: flex;
        flex-wrap: nowrap;

        & > *:not(:last-child) {
            margin-right: 15px;
        }
    }

    &__content {
        display: flex;
        flex-wrap: wrap;

        > *:not(:last-child) {
            margin-right: 10px;
        }

        > * {
            margin-bottom: 15px;
        }
    }
}
