.date-displayer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: -10px;
  padding-right: 10px;
  margin-top: 5px;
  flex-grow: 1;

  &__time-container {
    text-align: right;
    padding-bottom: 10px;
  }
}
