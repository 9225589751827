.agenda-item {
  position: relative;

  &__title {
    margin: 0;
    font-size: 17px;
  }

  &__description {
    margin-left: 18px;
  }

  &__actions-container {
    flex-wrap: wrap;
  }

  &__title-button {
    white-space: normal;
    text-align: left;
    height: auto;
  }

  &__scroll-element {
    position: absolute;
    top: -60px;
  }
}
