.page-title {
    position: sticky;
    width: calc(100% + 20px);
    padding: 0 24px 20px;
    z-index: 3;
    top: 0px;

    &::after {
        content: "";
        position: absolute;
        height: 24px;
        background: inherit;
        width: 100%;
        top: -24px;
    }

    &__container {
        width: calc(100% - 180px);
        display: flex;
        flex-wrap: wrap;
        padding: 3px 0 15px;
        align-items: flex-end;

        & > *:not(:last-child) {
            margin-right: 15px;
        }
    }

    &__icon-container {
        margin-right: 5px;
    }

    &__title {
        margin-bottom: 0px !important;
    }

    &__subtitle {
        margin: 0 !important;
        padding-bottom: 0px;
    }

    &__additional-content-container {
        margin-top: 20px;
    }
}
